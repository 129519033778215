import { Injectable } from "@angular/core";
import { DataBaseService } from "./data-base.service";
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';
import { CustomerDto } from "../model/CustomerDto";
import { SiteDto } from "../model/SiteDto";
import { CustomerReviewDto } from "../model/CustomerReviewDto";



@Injectable()
export class SitesService extends DataBaseService {

    private ngUnsubscribe: Subject<void> = new Subject();

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getSite(id: number): Promise<SiteDto> {

        let url = `${this.apiEndpoint}/sites/${id}`;
        let resultPromise = this.httpClient.get<SiteDto>(url).toPromise();

        return resultPromise;
    }

    getSites(): Promise<SiteDto[]> {

        let url = `${this.apiEndpoint}/sites`;
        let resultPromise = this.httpClient.get<SiteDto[]>(url).toPromise();

        return resultPromise;
    }

    getSiteReviewsByProductCategoryKey(categoryKey: string): Promise<CustomerReviewDto[]> {

        let url = `${this.apiEndpoint}/sites/reviews/categorykey/${categoryKey}`;
        let resultPromise = this.httpClient.get<CustomerReviewDto[]>(url).toPromise();

        return resultPromise;
    }
}
